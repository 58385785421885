import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";

import PhoneInput from "react-phone-input-2";

import "react-phone-input-2/lib/material.css";
import { GET_COUNTRIES_LOCALIZATION } from "../../../graphql/queries/queriesInstasport";
import { useQuery } from "@apollo/client";
import { getPhoneLocale } from "../../../utils/phone/getPhoneLocale";

const PHONE_CODE_DEFAULT = "380";

const PhoneInputContainer = ({
  // placeholderValue,
  errorMessage,
  onFocus,
  onBlur,
  onChange = () => null,
  inputRef,
  inputProps = {},
  value,
  allCountries = false,
}) => {
  const [
    { onlyCountries, localization, country, phoneCodes },
    setCountrySettings,
  ] = useState(
    allCountries
      ? {
          onlyCountries: undefined,
          localization: {},
          country: undefined,
          phoneCodes: [],
        }
      : {
          onlyCountries: [],
          localization: undefined,
          country: undefined,
          phoneCodes: [],
        }
  );

  useQuery(GET_COUNTRIES_LOCALIZATION, {
    // variables: { slug: COUNTRY },
    onCompleted: async ({ countries }) => {
      // const countries = [
      //   { slug: "UA", id: 1, title: "Украина" },
      //   { slug: "KZ", id: 2, title: "Казахстан" },
      // ];

      // const countrySettings = {};
      // const { title, alpha2 } = country;

      // const languageCode = alpha2.toLowerCase();
      // countrySettings.onlyCountries = [languageCode];

      // countrySettings.localization = { [languageCode]: title };
      const countrySettings = allCountries
        ? countries.reduce(
            (acc, { title, alpha2 }) => {
              const lowerCaseSlug = alpha2.toLowerCase();
              return {
                onlyCountries: undefined,
                localization: { ...acc.localization, [lowerCaseSlug]: title },
                phoneCodes: [],
              };
            },
            { onlyCountries: undefined, localization: {}, phoneCodes: [] }
          )
        : countries.reduce(
            (acc, { title, alpha2, phoneCode }) => {
              const lowerCaseSlug = alpha2.toLowerCase();
              return {
                onlyCountries: acc.onlyCountries.concat(lowerCaseSlug),
                localization: { ...acc.localization, [lowerCaseSlug]: title },
                phoneCodes: acc.phoneCodes.concat(phoneCode),
              };
            },
            { onlyCountries: [], localization: {}, phoneCodes: [] }
          );

      countrySettings.country = await getPhoneLocale(countries, allCountries);
      // countrySettings.onlyCountries.push('ua')
      setCountrySettings(countrySettings);
    },
    onError: () => {
      setCountrySettings(
        allCountries
          ? {
              onlyCountries: undefined,
              localization: {
                ua: "Україна",
              },
              country: "ua",
              phoneCodes: [],
            }
          : {
              onlyCountries: ["ua"],
              localization: {
                ua: "Україна",
              },
              country: "ua",
              phoneCodes: [PHONE_CODE_DEFAULT],
            }
      );
    },
    // skip: allCountries,
  });

  const handledPhoneNumber = useMemo(() => {
    if (!phoneCodes.length) return value;

    const hasCode = phoneCodes.some((code) => {
      return value.startsWith(code) || value.startsWith(`+${code}`);
    });

    if (hasCode) return value;
    return "";
  }, [phoneCodes, value]);

  return !localization ? (
    <input
      className={"sign-up-section__input"}
      type={"text"}
      placeholder={""}
      maxLength={0}
      // disabled
    />
  ) : (
    <PhoneInput
      onChange={(currentValue, _, { target }) => {
        if (!currentValue) return;
        onChange({ target, currentValue });
      }}
      autoFormat={false}
      showDropdown={false}
      countryCodeEditable={!!allCountries}
      inputProps={{
        ...inputProps,
        ...(inputRef && { ref: inputRef }),
        onFocus,
        onBlur,
        className: errorMessage
          ? "sign-up-section__input sign-up-section__input--phone sign-up-section__input_error"
          : "sign-up-section__input sign-up-section__input--phone",
      }}
      value={handledPhoneNumber}
      placeholder={""}
      dropdownClass={"sign-up-section__dropdown"}
      country={country}
      disableDropdown={onlyCountries?.length === 1}
      onlyCountries={onlyCountries} //["ua", "ru", "us"]
      localization={localization} //{ ua: "Україна", ru: "Россия" }
      preserveOrder={["onlyCountries", "preferredCountries"]}
      specialLabel={""}
      renderStringAsFlag={allCountries ? undefined : !onlyCountries?.length}
    />
  );
};

PhoneInputContainer.propTypes = {
  isRequired: PropTypes.bool,
  inputType: PropTypes.string,
  placeholderValue: PropTypes.string,
  errorMessage: PropTypes.string,
  onFocusFunction: PropTypes.func,
  onBlurFunction: PropTypes.func,
  index: PropTypes.number,
  activeFocusInput: PropTypes.number,
  isInputError: PropTypes.bool,
  inputProps: PropTypes.object,
  errorButtonTitle: PropTypes.string,
  errorButtonFunc: PropTypes.func,
};

PhoneInputContainer.defaultProps = {
  isRequired: false,
  inputType: "text",
  placeholderValue: "",
  errorMessage: "",
  onFocusFunction: () => {},
  onBlurFunction: () => {},
  index: -1,
  activeFocusInput: -1,
  isInputError: false,
  inputProps: {},
  errorButtonTitle: "",
  errorButtonFunc: () => {},
};

export default PhoneInputContainer;
